import React from 'react';

import { Link, Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  logo: {
    textAlign: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}));

const Home = () => {
  const classes = useStyles();

  return (
    <>
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="md">
            <div className={classes.logo}>
              <img width="100" src="./logo512.png" alt="logo" />
            </div>

            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              ClashTribe
            </Typography>
            <Typography align="center" color="textSecondary" paragraph>
              Clash Tribe is a social media platform for Clash of Clans players, where people from
              around the world can come to create and share game related content such as base
              layouts and attack strategies, recruit players for their clans and / or advertise their clan!
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.lglab.clashtribe"
                    target="_blank"
                  >
                    <img src="./assets/google-play-button.png" alt="Google Play" />
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="https://apps.apple.com/app/id1517215530" target="_blank">
                    <img src="./assets/app-store-button.png" alt="App Store" />
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </main>
    </>
  );
};

export default Home;
