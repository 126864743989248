import Home from '../containers/Home';
import Terms from '../containers/Terms';
import Privacy from '../containers/Privacy';

export const routes = [
  {
    key: 'terms',
    title: 'Terms and Conditions',
    path: '/terms-and-conditions',
    component: Terms,
  },
  {
    key: 'privacy',
    title: 'Privacy Policy',
    path: '/privacy-policy',
    component: Privacy,
  },
  {
    key: 'home',
    title: 'Home',
    path: '/',
    component: Home,
  },
];
