import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Copyright from './components/Copyright';
import Header from './components/Header';
import Home from './containers/Home';

import { routes } from './navigation/routes';

import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#020421',
    },
    secondary: {
      main: '#F4AD1D',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    marginTop: 'auto',
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Router>
          <CssBaseline />
          <Header />
          <main className={classes.content}>
            <nav>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms</Link>
                </li>
              </ul>
            </nav>

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
              {routes.map(({ key, title, path, component: Component }, i) => (
                <Route
                  key={key}
                  title={title}
                  path={path}
                  render={(props) => <Component {...props} />}
                />
              ))}
              <Route path="*" title="Home">
                <Home />
              </Route>
            </Switch>
          </main>

          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
