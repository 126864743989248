import React from 'react';
import { Typography, Link as MUILink } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    color: 'blue',
    textDecoration: 'underline'
  },
}));

const Copyright = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        Copyright &copy; Clash Tribe 2020. |{' '}
        <Link to="/terms-and-conditions">Terms and Conditions</Link> -{' '}
        <Link to="/privacy-policy">Privacy Policy</Link> -{' '}
        <MUILink href="mailto:info@clashtribe.com" className={classes.link}>
          Contact Us
        </MUILink>
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        This material is unofficial and is not endorsed by Supercell. For more information see
        Supercell's Fan Content Policy:{' '}
        <MUILink href="https://supercell.com/en/fan-content-policy/" target="_blank" className={classes.link}>
          www.supercell.com/fan-content-policy
        </MUILink>
      </Typography>
    </>
  );
};

export default Copyright;
